.opc-wrapper {
    .form-discount {
        max-width: 500px;

        .field {
            .label {
                @extend .abs-visually-hidden;
            }
        }

        .actions-toolbar {
            .primary {
                float: left;
            }

            .action-apply {
                @extend .btn, .btn--secondary;
            }
        }
    }

    .payment-option.discount-code {
        margin-bottom: 30px;
    }
}
