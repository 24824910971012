//
//  Icons variables
//  _____________________________________________

$icon__position            : before !default;
$icon__text-hide           : false !default;
$icon__height              : 26px !default;
$icon__width               : 26px !default;
$icon__margin              : '' !default;
$icon__vertical-align      : middle !default;

$icon-image__position-x    : 0 !default;
$icon-image__position-y    : 0 !default;

$icon-sprite__position-x   : 0 !default;
$icon-sprite__position-y   : 0 !default;
$icon-sprite__grid         : 26px !default;

$icon-font                 : $icons__font-name !default;
$icon-font__position       : $icon__position !default;
$icon-font__text-hide      : $icon__text-hide !default;
$icon-font__margin         : $icon__margin !default;
$icon-font__size           : inherit !default;
$icon-font__line-height    : $icon-font__size !default;
$icon-font__color          : inherit !default;
$icon-font__color-hover    : false !default;
$icon-font__color-active   : false !default;
$icon-font__color-faded    : $theme-color-light-gray1 !default;
$icon-font__vertical-align : $icon__vertical-align !default;
$icon-font__display        : inline-block !default;

$icon-calendar__font-size  : 25px !default;

//
//  Variables for Iclicks icons
//  ---------------------------------------------

// - Use https://icomoon.io/app to edit icons
// - Paste the variables from variables.scss below
// - Don't forget to convert to woff2
// - Don't forget to upload selection.json

$icon-thumbs-down: "\e912";
$icon-thumbs-up: "\e913";
$icon-comment: "\e620";
$icon-clock: "\e905";
$icon-moon: "\e906";
$icon-delivery: "\e90a";
$icon-filter: "\e900";
$icon-private: "\e629";
$icon-gift-registry: "\e62b";
$icon-present: "\e62a";
$icon-download: "\e626";
$icon-undo: "\e628";
$icon-print: "\e624";
$icon-help: "\e623";
$icon-warning-circle: "\e90b";
$icon-warning: "\e602";
$icon-account: "\e627";
$icon-edit: "\e601";
$icon-wishlist-empty: "\e909";
$icon-wishlist-full: "\e600";
$icon-update: "\e603";
$icon-trash: "\e604";
$icon-star-empty: "\e625";
$icon-star: "\e605";
$icon-settings: "\e606";
$icon-menu: "\e609";
$icon-location: "\e60a";
$icon-info: "\e60c";
$icon-list: "\e60b";
$icon-grid: "\e60d";
$icon-cart: "\e611";
$icon-calendar: "\e612";
$icon-search: "\e615";
$icon-flag: "\e61b";
$icon-envelope: "\e61d";
$icon-compare-full: "\e61e";
$icon-pro: "\e902";
$icon-con: "\e901";
$icon-success: "\e60e";
$icon-error: "\e61f";
$icon-expand: "\e61c";
$icon-minus: "\e60f";
$icon-checkmark: "\e610";
$icon-remove: "\e616";
$icon-arrow-up: "\e613";
$icon-arrow-right: "\e907";
$icon-arrow-down: "\e614";
$icon-arrow-left: "\e908";
$icon-chevron-up: "\e903";
$icon-chevron-down: "\e904";
$icon-pointer-up: "\e618";
$icon-pointer-right: "\e619";
$icon-pointer-down: "\e607";
$icon-pointer-left: "\e61a";
$icon-up: "\e621";
$icon-next: "\e608";
$icon-down: "\e622";
$icon-prev: "\e617";
$icon-facebook: "\e90c";
$icon-instagram: "\e90d";
$icon-linkedin: "\e90e";
$icon-pinterest: "\e90f";
$icon-twitter: "\e910";
$icon-youtube: "\e911";
