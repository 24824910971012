$filter-title-background-color: $color-gray94;
$filter-link: $theme-text-color-lighter;
$filter-link-hover: $theme-text-color-lighter;
$filter-quantity: $color-gray46;

$filter-menu-width-s: calc(100vw - 42px);
$filter-menu-width-m: 40vw;

.trigger-filters {
    @include lib-link-as-button();
    @include lib-button-m();
    @include lib-button-primary();
    @include lib-css(border-radius, $button__border-radius);
    @include lib-icon-font(
        $_icon-font-content: $icon-filter,
        $_icon-font-margin: -2px 5px 0 -5px,
        $_icon-font-size: 15px,
        $_icon-font-line-height: 1
    );
    margin-bottom: 10px;

    .products.wrapper ~ .toolbar & {
        display: none;
    }
}

.filter {
    .column.main &.block {
        margin-bottom: 0;
    }

    &-title {
        strong {
            $_shadow: inset 0 1px 0 0 $color-white, inset 0 -1px 0 0 rgba($border-color__base, 0.3);
            @include lib-css(background-color, $toolbar-element-background);
            @include lib-css(box-shadow, $_shadow);
            border: 1px solid $border-color__base;
            border-radius: 3px;
            font-weight: $font-weight__medium;
            left: 0;
            line-height: 16px;
            padding: 7px $indent__s;
            position: absolute;
            text-align: center;
            top: 0;
            z-index: 2;
            display: none;

            &[data-count]:after {
                @include lib-css(color, $color-white);
                background: $color-orange-red1;
                border-radius: 2px;
                content: attr(data-count);
                display: inline-block;
                font-size: 0.8em;
                line-height: 1;
                margin: 0 $indent__xs;
                min-width: 1em;
                padding: 2px;
            }

            &.disabled {
                opacity: 0.5;
            }
        }
    }

    .block-subtitle {
        border-bottom: $border-width__base solid $border-color__base;
        font-size: $font-size__base;
        font-weight: $font-weight__regular;
        line-height: 1em;
    }

    &-subtitle {
        display: none;
    }

    &-current {
        margin: 0;

        .item {
            position: relative;
            z-index: 1;
        }

        .filter &-subtitle {
            border: none;
            display: block;
        }

        .action.remove {
            @include lib-icon-font(
                $_icon-font-content: $icon-remove,
                $_icon-font-margin: 0 0 0 5px,
                $_icon-font-size: 11px,
                $_icon-font-line-height: 15px,
                $_icon-font-text-hide: true,
                $_icon-font-color: $color-gray19,
                $_icon-font-color-hover: $color-gray19,
                $_icon-font-color-active: $color-gray19,
                $_icon-font-position: after
            );
        }
    }

    &-label {
        font-weight: $font-weight__medium;

        &::after {
            content: ': ';
        }
    }

    &-value {
        @include lib-css(color, $filter-quantity);
    }

    &-options {
        margin: 0;

        &-title {
            @include lib-heading(h4);
            font-size: 18px;
            cursor: default;
            margin: 0;
            overflow: hidden;
            padding: $indent__s $indent__s + 30px $indent__s 0;
            position: relative;
            word-break: break-all;
            z-index: 1;

            &::after {
                position: absolute;
                right: 0;
                top: 12px;
            }

            .active > & {
                @include lib-icon-font-symbol(
                    $_icon-font-content: $icon-up,
                    $_icon-font-position: after
                );
            }
        }

        &-content {
            margin: 0 0 30px 0;
            padding: $indent__s 0 0;

            .item {
                line-height: 1.5em;
                margin: $indent__s 0;
            }

            a.c-checkbox {
                @include lib-css(color, $filter-link);
                margin-left: -5px;
                margin-right: -5px;
                padding-left: 5px;
                padding-right: 7px;

                &:hover {
                    text-decoration: none;
                }
            }

            .label {
                cursor: pointer;
                color: $text__color;
            }

            .count {
                color: $text__color;

                &::before {
                    content: '(';
                }

                &::after {
                    content: ')';
                }
            }

            .filter-count-label {
                @include abs-visually-hidden();
            }
        }

        &-item {
            .item {
                display: flex;
                align-items: center;

                > a {
                    &:hover,
                    &.checked {
                        @include lib-icon-font(
                            $_icon-font-content: $icon-success,
                            $_icon-font-position: after,
                            $_icon-font-color: $theme-color-blue1,
                            $_icon-font-size: 12px
                        );

                        &::after {
                            position: absolute;
                            top: 50%;
                            left: 3px;
                            transform: translateY(-50%);
                        }
                    }
                }
            }

            a {
                position: relative;
                order: 2;

                &:not(.filter-collapse) {
                    padding-left: 30px;

                    &::before {
                        content: '';
                        position: absolute;
                        top: 50%;
                        left: 0;
                        height: 13px;
                        width: 13px;
                        border-radius: 3px;
                        border: 2px solid $theme-color-dark-gray5;
                        transform: translateY(-50%);
                    }
                }
            }

            input {
                order: 1;
            }
        }
    }
}

.filter-collapse {
    cursor: pointer;

    div {
        @include lib-icon-font(
            $_icon-font-content: $icon-chevron-down,
            $_icon-font-position: before,
            $_icon-font-margin: 0 0 0 -1px,
            $_icon-font-color: $theme-color-blue1,
            $_icon-font-size: 16px
        );
    }

    > .filter-collapse__close {
        @include lib-icon-font-symbol(
            $_icon-font-content: $icon-chevron-up,
            $_icon-font-position: before
        );
    }
}

.column.main {
    .filter {
        &-current,
        &-actions {
            padding: 10px 0;
            border: solid $border-color__base;
            border-width: 1px 0;
        }

        &-content {
            line-height: 1;
        }

        .items {
            flex-wrap: wrap;
        }

        .item {
            .remove {
                display: flex;
                margin: 4px;
                padding: 7px 14px;
                border: 1px solid $border-color__base;
                color: $brand__primary__color;
                border-radius: 20px;

                &:hover {
                    strong {
                        text-decoration: line-through;
                    }
                }
            }
        }

        strong {
            margin-right: 5px;
            font-weight: $font-weight__medium;
        }
    }
}

//
//  Mobile
//  _____________________________________________

@include max-screen($screen__l) {
    html {
        &.filter-active {
            height: 100%;
            overflow-x: hidden;
            width: 100%;

            body {
                height: 100%;
                overflow: hidden;
                position: relative;
                width: 100%;
            }

            .page-wrapper {
                height: 100%;
                overflow: hidden;
                position: relative;
            }

            .page-products .columns {
                z-index: 106;
            }

            .trigger-filters {
                &::after {
                    background: rgba(0, 0, 0, $overlay__opacity);
                    content: '';
                    display: block;
                    height: 100%;
                    position: fixed;
                    right: 0;
                    top: 0;
                    width: 100%;
                    z-index: 40;
                }
            }

            .filter-options {
                transform: translateX(0);
            }
        }
    }

    .filter {
        &.active {
            position: relative;
            visibility: visible;
            z-index: 99;

            .filter-options-item:last-child {
                margin-bottom: $indent__xl;
            }

            .filter-title {
                transform: translateX(0);

                strong {
                    @include lib-css(box-shadow, none);
                    background: none;
                    border: 0;
                    color: transparent;
                    left: auto;
                    right: 3px;
                    top: 10px;
                    @include lib-icon-font(
                            $icon-remove,
                        $_icon-font-color: $text__color__muted,
                        $_icon-font-size: 16px,
                        $_icon-font-position: after
                    );
                }
            }

            .filter-subtitle {
                @include lib-css(background, $toolbar-background);
                display: block;
                height: 50px;
                left: 0;
                line-height: 32px;
                position: fixed;
                right: 0;
                top: 0;
                z-index: 1;
                display: none;
            }

            .filter-options {
                display: block;
            }
        }
    }

    .filter {
        & &-subtitle {
            font-size: 20px;
            font-weight: $font-weight__light;
        }

        &-title {
            transform: translateX(-100%);
            height: 48px;
            left: 0;
            position: fixed;
            top: 0;
            z-index: 99;
            width: $filter-menu-width-m;
            background-color: $color-white;
            transition: transform 0.2s;
        }

        &-options {
            transform: translateX(-100%);
            @include lib-css(background, $color-white);
            bottom: 0;
            left: 0;
            position: fixed;
            width: $filter-menu-width-m;
            top: 48px;
            z-index: 999;
            transition: transform 0.2s;

            &-title {
                &::after {
                    right: 17px;
                }
            }
        }

        &-options-item {
            margin-left: 15px;
        }

        &-options-content {
            padding: 0;
        }
    }

    .filter .filter-current {
        .items {
            display: none;
        }

        &-subtitle {
            @include lib-icon-font(
                $_icon-font-content: $icon-down,
                $_icon-font-size: 13px,
                $_icon-font-position: before,
                $_icon-font-display: block
            );
            position: relative;
            text-transform: uppercase;
            z-index: 1;

            &::before {
                position: absolute;
                right: 0;
                top: 0;
            }

            &::after {
                @include lib-css(color, $text__color__muted);
                content: ' (' attr(data-count) ')';
                font-size: 0.9em;
            }
        }

        &.active {
            .block-subtitle {
                @include lib-icon-font-symbol(
                    $_icon-font-content: $icon-up,
                    $_icon-font-position: before
                );
            }

            .items {
                display: flex;
            }

            & + .block-actions {
                display: block;
            }
        }

        & + .block-actions {
            display: none;
        }
    }

    .column.main {
        .filter {
            &-current {
                margin-bottom: 10px;
            }

            &-current.active {
                margin-bottom: 0;
                border-width: 1px 0 0;
            }

            &-actions {
                margin-bottom: 10px;
                border-width: 0 0 1px;
            }
        }
    }

    .filter-no-options {
        .filter-title {
            &::before {
                background: rgba(255, 255, 255, 0.5);
                content: '';
                display: block;
                height: 40px;
                left: -15px;
                margin-top: -60px;
                position: relative;
                width: 100px;
                z-index: 99;
            }
        }

        .filter-content {
            margin-bottom: $indent__base;
        }
    }

    .page-with-filter {
        .columns {
            .sidebar-main {
                @include lib-vendor-prefix-order(0);
            }
        }
    }

    .page-wrapper {
        left: 0;
        transition: left 0.3s;
    }
}

.block.filter {
    .amshopby-filter-current {
        margin-bottom: 20px;

        .filter-current-subtitle {
            @include lib-heading(h4);
            font-size: 18px;
        }

        .amshopby-remove {
            top: 11px;
            right: 0;
            left: auto;
        }

        .amshopby-remove:after,
        .amshopby-remove:before {
            height: 2px;
            background-color: $brand__primary__color;
        }

        .amshopby-items {
            padding-left: 0;
        }

        .item.amshopby-item {
            display: flex;
            align-items: center;
            padding-left: 0;
        }

        .amshopby-filter-name {
            display: flex;
            margin-right: 5px;
            color: $theme-color-dark-gray3;
            font-weight: $font-weight__semibold;

            &::after {
                content: ': ';
            }
        }

        .amshopby-filter-value {
            padding-top: 3px;
            color: $theme-color-dark-gray3;
        }
    }
}

@include max-screen($screen__l) {
    .filter-current {
        .item {
            margin: 10px 0 0;
        }
    }
}

@include max-screen($screen__m) {
    .filter {
        &-title,
        &-options {
            width: $filter-menu-width-s;
        }
    }
}

@include max-screen($screen__s) {
    .trigger-filters {
        @include lib-button-responsive();
    }
}

@include min-screen($screen__l) {
    .page-with-filter {
        .sidebar {
            background-color: $theme-color-light-gray1;
            padding: 40px;
        }
    }

    .page-main {
        .sidebar {
            .filter {
                &-options-content {
                    display: block;
                }
            }
        }
    }

    .column.main {
        .filter {
            &-title {
                display: none;
            }

            &-current,
            &-actions {
                margin-bottom: 15px;
            }

            &-current {
                flex: 1;
                display: flex;
                align-items: center;
            }

            &-actions {
                display: flex;
                align-items: center;
            }

            &-content {
                display: flex;

                .items {
                    display: flex;
                }

                .item {
                    margin: 0;
                }
            }
        }
    }

    .filter {
        &.active &-options,
        &-options {
            background: transparent;
            clear: both;
            display: block;
            overflow: initial;
            position: static;
        }

        &-subtitle {
            display: none;
            position: static;
        }
    }

    .page-layout-1column {
        .toolbar-products {
            position: absolute;
            top: 0;
            width: 100%;
        }

        .products ~ .toolbar-products {
            position: static;
        }

        &.page-with-filter .column.main {
            padding-top: 45px;
            position: relative;
            z-index: 1;
        }

        .filter {
            &.block {
                border-top: 1px solid $border-color__base;
            }

            &-content {
                margin-top: $indent__s;
            }

            &-subtitle {
                display: none;
            }

            &-options {
                &-item {
                    border: 0;
                    display: inline-block;
                    margin-right: $indent__m;
                    position: relative;

                    &.active {
                        z-index: 2;

                        .filter-options-content {
                            visibility: visible;
                        }

                        &:hover {
                            z-index: 3;
                        }

                        &::after,
                        &::before {
                            @include lib-arrow(up, 8px, $color-black);
                            bottom: -1px;
                            content: '';
                            display: block;
                            left: $indent__xs;
                            position: absolute;
                            z-index: 3;
                        }

                        &::after {
                            @include lib-css(border-bottom-color, $color-white);
                            margin-top: 2px;
                            z-index: 4;
                        }
                    }
                }

                &-title {
                    padding: 0 $indent__base 0 0;

                    &::after {
                        right: 2px;
                        top: 3px;
                        z-index: 3;
                    }
                }

                &-content {
                    $_shadow: 0 3px 5px 0 rgba(50, 50, 50, 0.75);

                    @include lib-css(background, $color-white);
                    @include lib-css(box-shadow, $_shadow, 1);
                    border: 1px solid $border-color__base;
                    padding: $indent__xs 0;
                    position: absolute;
                    top: 100%;
                    visibility: hidden;
                    width: 180px;
                    z-index: 2;

                    .item {
                        margin: 0;
                        padding: $indent__xs;

                        a {
                            margin-left: 0;
                        }

                        &:hover {
                            background-color: $color-gray91;
                        }
                    }
                }
            }

            &-current {
                display: inline;
                line-height: 35px;

                &-subtitle {
                    @include lib-css(color, $text__color__muted);
                    display: inline;
                    font-size: $font-size__base;
                    font-weight: normal;
                    padding: 0;

                    &::after {
                        content: ':';
                    }
                }

                .item,
                .items {
                    display: inline;
                }

                .item {
                    margin-right: $indent__m;
                    white-space: nowrap;
                }

                .action.remove {
                    line-height: normal;
                }
            }

            &-actions {
                display: inline;
                white-space: nowrap;

                & ~ .filter-options {
                    margin-top: $indent__m;
                }
            }
        }
    }

    .trigger-filters {
        display: none;
    }
}
