//
//  Variables
//  _____________________________________________

$minicart__border-color       : $color-gray80 !default;
$minicart__padding-horizontal : $indent__base !default;

$minicart-qty__height         : 24px !default;

//
//  Minicart
//  ---------------------------------------------
.minicart-wrapper {
    @include lib-dropdown(
        $_toggle-selector                     : '.action.showcart',
        $_options-selector                    : '.block-minicart',
        $_dropdown-toggle-icon-content        : $icon-cart,
        $_dropdown-toggle-active-icon-content : $icon-cart,
        $_dropdown-list-item-padding          : false,
        $_dropdown-list-item-hover            : false,
        $_icon-font-position                  : before,
        $_icon-font-size                      : 26px,
        $_icon-font-line-height               : 22px,
        $_icon-font-margin                    : 1px 0 3px 0,
        $_icon-font-color                     : false,
        $_icon-font-color-hover               : false,
        $_icon-font-color-active              : false,
        $_icon-font-display                   : false
    );
}

.block-minicart {
    .items-total {
        float: left;
        margin: 0 $indent__s;

        .count {
            font-weight: $font-weight__bold;
        }
    }

    .subtotal {
        margin: 0 $indent__s $indent__s;
        text-align: right;

        .label {
            @extend .abs-colon;
        }
    }

    .amount {
        .price-wrapper {
            &:first-child {
                .price {
                    font-size: $font-size__l;
                    font-weight: $font-weight__bold;
                }
            }
        }
    }

    .subtitle {
        display: none;

        &.empty {
            display: block;
            font-size: 14px;
            padding: $indent__l 0 $indent__base;
            text-align: center;
        }
    }

    .text {
        &.empty {
            text-align: center;
        }
    }

    .block-content {
        > .actions {
            margin-top: 15px;
            text-align: center;

            > .primary {
                margin: 0 0 15px;

                .action {
                    &.primary {
                        @extend .abs-button-l;
                        display: block;
                        margin-bottom: 15px;
                        width: 100%;

                        &:last-child {
                            margin-bottom: 0;
                        }
                    }
                }
            }
        }
    }

    .block-category-link,
    .block-product-link,
    .block-cms-link,
    .block-banners {
        margin: 15px 0 0;
        text-align: center;
    }
}

.minicart-wrapper {
    .block-minicart {
        @include lib-css(padding, 0 $minicart__padding-horizontal 25px);
        @include lib-css(border, $middle-bar__action__dropdown-border);
        top: calc(100% - 1px);
        right: 0;
        width: 320px;
        z-index: $z-index__dropdown-menu;
        border-radius: $dropdown-list-pointer__border-radius;

        .block-title {
            @include lib-heading(h3);
            margin: 0;
            padding: 15px 0;
        }

        &::after {
            left: auto;
            right: 17px;
        }

        &::before {
            left: auto;
            right: 18px;
        }
    }

    .product {
        .actions {
            margin: -24px 0 0;
            text-align: right;

            > .primary,
            > .secondary {
                display: inline;

                &:not(:last-child) {
                    margin-right: 15px;
                }
            }
        }
    }

    .action {
        &.showcart {
            .text {
                @extend .abs-visually-hidden;
            }

            &::before {
                position: relative;
                top: 1px;
            }
        }

        &.close {
            @include lib-button-icon(
                $icon-remove,
                $_icon-font-size        : 16px,
                $_icon-font-line-height : 16px,
                $_icon-font-text-hide   : true
            );
            @include lib-button-reset();
            height: 40px;
            position: absolute;
            right: 0;
            top: 0;
            width: 40px;
        }
    }
}

.header .minicart-items-wrapper {
    @include lib-css(margin, 0 (-$minicart__padding-horizontal) 20px);
    border-left: 0;
    border-right: 0;
    overflow-x: auto;
    padding: 0 20px;
}

.header .minicart-items {
    @include lib-list-reset-styles();
    padding-left: 0;

    .product-item {
        padding: 15px 0;
        @include lib-css(border-top, 1px solid $minicart__border-color);

        &:last-child {
            @include lib-css(border-bottom, 1px solid $minicart__border-color);
        }
    }

    .product-image-wrapper {
        @extend .abs-reset-image-wrapper;
    }

    .product-item-pricing {
        margin-left: 10px;

        .label {
            display: inline-block;
            width: 4.5rem;
        }
    }

    .price-minicart {
        margin-bottom: $indent__xs;
    }

    .item > .product {
        display: flex;

        > .product-item-photo {
            margin-right: 15px;
        }

        .toggle {
            @include lib-icon-font(
                $_icon-font-content     : $icon-down,
                $_icon-font-size        : 12px,
                $_icon-font-line-height : 12px,
                $_icon-font-text-hide   : false,
                $_icon-font-position    : after,
                $_icon-font-display     : block
            );
            cursor: pointer;
            position: relative;

            &::after {
                @include lib-css(color, $color-gray56);
                position: static;
                margin: 0 0 0 $indent__xs;
            }
        }

        &.active {
            > .toggle {
                @include lib-icon-font-symbol(
                    $_icon-font-content  : $icon-up,
                    $_icon-font-position : after
                );
            }
        }
    }

    .product-item-name {
        @extend .product-item-name;
        @include text-truncate(15px, $fixed-height: false);
        font-weight: $font-weight__regular;
        margin: 0 0 5px;

        .product-qty {
            &::after {
                content: 'x';
            }
        }
    }

    .product-item-flex {
        display: flex;
        justify-content: space-between;
    }

    .product-item-details {
        flex: 1;

        .price {
            @extend .abs-price-md;
            line-height: 1.5;
        }

        .weee[data-label] {
            @include lib-font-size(11);

            .label {
                @extend .abs-no-display;
            }
        }

        .details-qty {
            margin-top: $indent__s;
        }
    }

    .product-options {
        display: flex;
        flex-wrap: wrap;
        font-size: 13px;
        color: $text__color__muted;

        &__list {
            margin-right: 10px;
        }

        .tooltip.toggle {
            @include lib-icon-font(
                $icon-down,
                $_icon-font-size        : 28px,
                $_icon-font-line-height : 28px,
                $_icon-font-text-hide   : true,
                $_icon-font-margin      : -3px 0 0 7px,
                $_icon-font-position    : after
            );

            .details {
                display: none;
            }
        }
    }

    .details-qty,
    .price-minicart {
        .label {
            @extend .abs-colon;
        }
    }

    .item-qty {
        margin-right: $indent__s;
        text-align: center;
        width: 40px;
    }

    .update-cart-item {
        @include lib-font-size(11);
        vertical-align: top;
    }

    .subtitle {
        display: none;
    }

    .action {
        &.edit,
        &.delete {
            @include lib-icon-font(
                $icon-edit,
                $_icon-font-size         : 18px,
                $_icon-font-line-height  : 20px,
                $_icon-font-text-hide    : true,
                $_icon-font-color: $text__color,
                $_icon-font-color-hover: $text__color__hover,
                $_icon-font-color-active: $text__color__hover
            );
        }

        &.delete {
            @include lib-icon-font-symbol(
                $_icon-font-content : $icon-trash
            );
        }
    }
}

//
//  Mobile
//  _____________________________________________

@include max-screen($screen__xs) {
    .minicart-wrapper .block-minicart {
        width: 290px;
    }
}

@include max-screen($screen__m) {
    .minicart-wrapper {
        .action.showcart {
            margin-left: 0;

            &,
            &.active {
                &::before {
                    font-size: 21px;
                }
            }
        }

        .block-minicart {
            display: none;

            &::after {
                left: auto;
                right: 1px;
            }

            &::before {
                left: auto;
                right: 2px;
            }
        }
    }

    .showcart {
        .counter-number,
        .counter-label {
            font-weight: $font-weight__semibold
        }

        .counter-label {
            margin-left: -4px;
        }
    }
}

//
//  Desktop
//  _____________________________________________

@include min-screen($screen__m) {
    .minicart-wrapper {

        .block-minicart {
            width: 390px;
            padding: 5px 20px 20px 20px;
        }

        .minicart-items {
            padding: 0;
        }
    }
}
