.amcard-field-container {
    &.-cart {
        margin-bottom: 40px;

        .amcard-title {
            @extend h2;
            display: block;
            margin-bottom: 20px;
        }
    }

    .amgcard-datalist-container {
        width: 50%;

        input {
            height: 50px;
        }
    }

    .amcard-field-block {
        .amcard-button {
            @extend .btn, .btn--secondary;
            margin-top: 15px;
        }
    }

    .amcard-check {
        @extend .btn, .btn--primary;
        margin-top: 20px;
    }
}

.product-info-main {
    .amcard-field-block {
        .amcard-button {
            @extend .btn, .btn--secondary;
            flex: 1;
            margin-top: 0;
            margin-left: 15px;
            padding: 0;
        }
    }

    .amcard-general-container .amcard-form-container:nth-child(1) .amcard-field-container .amcard-field-block {
        flex-wrap: nowrap !important;
    }
}
