.switcher {
    display: flex;
    align-items: center;
    font-weight: $font-weight__semibold;

    a {
        @include lib-link-all(
            $_link-color: $color-white
        );
    }

    .label {
        margin-right: 10px;
        font-size: 14px;
        font-weight: $font-weight__regular;
        color: $color-white;
    }

    .switcher-trigger {
        position: relative;
        color: $color-white;
    }

    .options {
        @include lib-dropdown(
            $_dropdown-actions-padding: 0,
            $_dropdown-list-item-padding: 0,
            $_dropdown-toggle-icon-content: $icon-down,
            $_dropdown-toggle-active-icon-content: $icon-up,
            $_icon-font-text-hide: true,
            $_icon-font-size: 10px,
            $_icon-font-color: $color-white,
            $_icon-font-vertical-align: middle,
            $_icon-font-line-height: 0,
            $_dropdown-list-background: $theme-color-blue1
        );
        display: flex;
        align-items: center;

        > .action.toggle,
        > .action.toggle.active {
            display: flex;
            align-items: center;

            &::after {
                position: absolute;
                top: 55%;
                right: -8px;
            }
        }

        ul.dropdown {
            top: 25px;
            left: -15px;
            border-radius: 0 0 4px 4px;
            box-shadow: none;

            li {
                font-size: 10px;

                &:hover {
                    background: $theme-color-blue1;
                }
            }

            .switcher-option {
                padding: 0 15px;

                &:last-child {
                    padding-bottom: 8px;
                }
            }

            a {
                display: flex;
                align-items: center;

                &:hover,
                &:focus {
                    text-decoration: none;
                }
            }
        }
    }

    &-currency {
        .switcher-option {
            min-width: 145px;
        }
    }
}

.switcher-image {
    display: flex;
    align-items: center;
    margin-right: 5px;
    font-size: 13px;

    &::before {
        content: '';
        width: 18px;
        height: 14px;
        display: inline-block;
        margin-right: 5px;
    }

    &.view-en_GB::before {
        background-image: url('../images/languages/en.jpg');
    }

    &.view-default::before,
    &.view-nl::before {
        background-image: url('../images/languages/nl.jpg');
    }
}

@include max-screen(800px) {
    .switcher {
        margin-right: 5px;

        .label {
            display: none;
        }
    }
}
