@include min-screen($screen__m) {
    .customer-welcome {
        position: relative;

        &.active {
            .customer-menu {
                display: block;
            }
        }
    }

    .customer-menu {
        position: absolute;
        top: 100%;
        left: 75%;
        transform: translateX(-50%);
        display: none;
        z-index: 5;
        cursor: pointer;

        ul {
            min-width: 115px;
            padding: 8px 12px;
            background-color: $color-white;
            border: 1px solid $theme-color-blue1;
            border-radius: 4px;
        }

        li {
            margin-bottom: 5px;
            list-style: none;
        }

        a {
            @include lib-link-all(
                $_link-color: $theme-color-dark-gray5
            );
            font-size: 14px;
        }
    }

    .login-wrapper {
        @include lib-dropdown(
            $_toggle-selector: '.action.showlogin',
            $_options-selector: '.block-minilogin',
            $_dropdown-toggle-icon-content: false,
            $_dropdown-toggle-active-icon-content: false,
            $_dropdown-list-item-padding: false,
            $_dropdown-list-item-hover: false,
            $_icon-font-size: 11px,
            $_icon-font-line-height: 11px,
            $_icon-font-margin: false,
            $_icon-font-color: false,
            $_icon-font-color-hover: false,
            $_icon-font-color-active: false,
            $_icon-font-display: false
        );
        display: block;

        a {
            font-size: 14px;
        }

        .showlogin {
            span {
                font-size: 12px;
                color: $theme-color-dark-gray1;
            }
        }

        .block-minilogin {
            @include lib-css(border, $middle-bar__action__dropdown-border);
            top: calc(100% - 1px);
            right: 0;
            width: 250px;
            z-index: $z-index__dropdown-menu;
            overflow: hidden;
            border-radius: $dropdown-list-pointer__border-radius;
        }

        .form-login {
            padding: 15px;

            .label {
                font-size: 14px;
                color: $theme-color-dark-gray5;
            }
        }

        .field {
            margin-bottom: 10px;
        }

        .dropdown-footer {
            padding: 15px;
            background-color: $panel__background-color;
            line-height: 1.3;
            font-size: 14px;
        }
    }

    .login-wrapper.logged-in {
        display: none;
    }

    .customer-welcome .login-wrapper {
        display: block;
    }
}

@include max-screen($screen__m) {
    .customer-welcome .login-wrapper {
        display: none;
    }
}
