.home-content-block {
    width: 100%;
    height: auto;
    position: relative;
}

.home-content-text-block-container {
    background-color: $theme-color-light-gray1;
}

.home-content-text-block {
    padding-top: 100px;
    padding-bottom: 100px;

    h2,
    h3 {
        font-size: 26px;
    }
}

.content-block {
    h3 {
        margin-bottom: 35px;
        font-size: 26px;
    }

    &__inner {
        display:flex;
    }

    &__inner-text {
        padding: 40px;
        width: 100%;
        max-width: 600px;
        border-radius: 20px;
        background-color: $color-white;
        box-sizing: border-box;
    }

    &-img-wrapper {
        width: 100%;
        min-height: 780px;
        background-size: cover;
        background-position: center;
    }
}

@include min-screen($screen__m) {
    .content-block {
        &__inner-text {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
        }
    }
}

@include max-screen($screen__m) {
    .content-block {
        &__inner-text {
            margin-top: 60px;
        }
    }
}
