.product-overview-block {
    display: flex;
    margin-bottom: 10px;
    padding: 25px 0;
    background-color: $color-white;
    border-radius: 6px;
}

.product-overview-summary {
    height: (17px * 1.16) * 2 + 13px + 17px;
    margin-bottom: 5px;
    line-height: 0; // To reset the space between the title and number
}

.product-overview-bottom {
    margin-top: 10px;
}

@include min-screen($screen__m) {
    .product-overview-block {
        padding: 25px 0;
        display: flex;
        flex-direction: column;
    }

    .product-overview-summary {
        font-size: 24px;
        height: (24px * 1.16) * 2 + 13px + 17px;
    }
}

@include min-screen($screen__l) {
    .product-overview-bottom {
        justify-content: space-between;
        margin-top: 25px;
    }
}

@include max-screen($screen__l) {
    .product-overview-bottom {
        align-items: flex-start;
        flex-direction: column;
    }

    .product-overview-color-selector {
        order: -1;
        margin-bottom: 20px;
    }
}
