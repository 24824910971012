//
//  Variables
//  _____________________________________________

$checkout-wrapper__margin: $indent__base !default;
$checkout-wrapper__columns: 16 !default;

$checkout-step-title__border: $border-width__base solid $border-color__base !default;
$checkout-step-title__font-size: 26px !default;
$checkout-step-title__font-weight: $font-weight__light !default;
$checkout-step-title__padding: $indent__s !default;

$checkout-step-title-mobile__font-size: 18px !default;

.page-layout-checkout {
    .page-header {
        margin-bottom: $indent__m;
    }
}

.checkout-index-index {
    .page-title-wrapper {
        @extend .abs-visually-hidden;
    }
}

.checkout-container {
    @extend .abs-add-clearfix;
    @include lib-css(margin, 0 0 $checkout-wrapper__margin);
}

.opc-wrapper {
    @include lib-css(margin, 0 0 $checkout-wrapper__margin);

    .opc {
        @extend .abs-reset-list;
    }

    .step-title {
        @extend .abs-checkout-title;
    }

    .step-content {
        margin: 0 0 $indent__xl;

        .control._with-tooltip {
            .field-tooltip.toggle {
                display: none;
            }

            input {
                width: 100%;
            }
        }
    }
}

.street {
    .field._required {
        label span {
            &::after {
                content: '*';
                color: $color-red10;
                font-size: 1.4rem;
                margin: 0 0 0 5px;
            }
        }
    }
}

.checkout-agreements-block .action-show {
    position: relative;
    bottom: 6px;
}

.checkout-index-index {
    .nav-sections,
    .nav-toggle,
    .header-middle-bar__search,
    .header-middle-bar__user,
    .authentication-dropdown .modal-header {
        display: none !important;
    }

    .logo {
        margin-left: 0;
    }
}

.checkout-onepage-success,
.checkout_onepage_success {
    @extend .abs-add-clearfix;

    .print {
        display: none;
    }
}

//
//  Mobile
//  _____________________________________________

@include max-screen($screen__s) {
    .opc-wrapper {
        .step-title {
            @include lib-css(font-size, $checkout-step-title-mobile__font-size);
            border-bottom: 0;
            padding-bottom: 0;
        }
    }
}

//
//  Desktop
//  _____________________________________________

@include min-screen($screen__m) {
    .opc-wrapper {
        @extend .abs-add-box-sizing-desktop-m;
        @include lib-layout-column(2, 1, $checkout-wrapper__columns);
        padding-right: $indent__l;
    }

    .checkout-onepage-success,
    .checkout_onepage_success {
        .print {
            @include lib-button();
            @include lib-link-as-button();
            float: right;
        }
    }
}
@include max-screen($screen__m) {
    .opc-estimated-wrapper {
        .minicart-wrapper {
            .action.showcart {
                .counter.qty {
                    min-width: 15px;
                    width: auto;
                    font-size: 12px;
                    display: inline-flex;
                    position: relative;
                    color: #999;
                    border-color: #999;
                }
            }
        }
    }
    #checkout {
        .authentication-wrapper {
            .action-auth-toggle {
                position: relative;
                top: 20px;
            }
        }
    }
}
