@include lib-font-face(
    $family-name: $font-family-name__base,
    $font-path: '../fonts/Roboto/Roboto-Regular',
    $font-weight: 400,
    $font-style: normal
);

@include lib-font-face(
    $family-name: $font-family-name__base,
    $font-path: '../fonts/Roboto/Roboto-Italic',
    $font-weight: 400,
    $font-style: italic
);

@include lib-font-face(
    $family-name: $font-family-name__base,
    $font-path: '../fonts/Roboto/Roboto-Medium',
    $font-weight: 500,
    $font-style: normal
);

@include lib-font-face(
    $family-name: $font-family-name__base,
    $font-path: '../fonts/Roboto/Roboto-Bold',
    $font-weight: 700,
    $font-style: normal
);

//
// Roboto Slab Font
//  _____________________________________________
@include lib-font-face(
    $family-name: $font-family-name__base_slab,
    $font-path: '../fonts/RobotoSlab/RobotoSlab-Regular',
    $font-weight: 400,
    $font-style: normal
);

@include lib-font-face(
    $family-name: $font-family-name__base_slab,
    $font-path: '../fonts/RobotoSlab/RobotoSlab-Medium',
    $font-weight: 500,
    $font-style: normal
);

@include lib-font-face(
    $family-name: $font-family-name__base_slab,
    $font-path: '../fonts/RobotoSlab/RobotoSlab-Bold',
    $font-weight: 600,
    $font-style: normal
);

@include lib-font-face(
    $family-name: $font-family-name__base_slab,
    $font-path: '../fonts/RobotoSlab/RobotoSlab-Black',
    $font-weight: 900,
    $font-style: normal
);

//
//  Desktop
//  _____________________________________________

@include min-screen($screen__m) {
    h1 {
        @include lib-css(font-size, $h1__font-size-desktop);
        @include lib-css(margin-bottom, $h1__margin-bottom__desktop);
    }
}

.items {
    @include lib-list-reset-styles();
}

.std {
    @include lib-typography-cms-lists();
}
