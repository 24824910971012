.header-chevron {
    @include lib-icon-font(
        $_icon-font-content         : $icon-chevron-down,
        $_icon-font-position        : after,
        $_icon-font-margin          : 0 0 0 7px,
        $_icon-font-size            : 11px,
        $_icon-font-color           : false ,
        $_icon-font-color-hover     : false,
        $_icon-font-color-active    : false,
        $_icon-font-display         : false
    );

    &.active {
        @include lib-icon-font-symbol(
            $_icon-font-content  : $icon-chevron-up,
            $_icon-font-position : after
        );
    }
}

.header-middle-bar {
    &__inner {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;
        padding: 15px 0 15px 0;
    }

    &__search {
        .block-search {
            border-radius: 50px;
            background: $theme-color-light-gray1;

            input[type='search'] {
                height: 50px;
                background: transparent;
                box-shadow: none;
            }
        }
    }

    &__logo {
        display: flex;
        align-items: center;
        max-width: 45%;
    }

    &__header-icons {
        display: flex;
        margin-left: 10px;
    }

    &__nav-toggle {
        padding-right: 10px;
    }

    &__user.in-header {
        @include lib-list-reset-styles();
        display: flex;
        align-items: center;
        justify-content: flex-end;
        margin-left: 25px;

        > li {
            margin: 0;

            > a {
                @include lib-icon-font(
                    $_icon-font-content     : $icon-account,
                    $_icon-font-line-height : 1,
                    $_icon-font-size        : 25px,
                    $_icon-font-color       : $middle-bar__action__color,
                    $_icon-font-color-hover : $middle-bar__action__color
                );
                @extend .abs-header-icons-label;
                display: flex;
                flex-direction: column;
            }
        }
    }

    .action.showlogin,
    .action.showcart,
    .customer-name {
        @extend .header-chevron;
    }

    .action.showcart {
        position: relative;
        white-space: nowrap;
        display: flex;
        flex-direction: column;

        span {
            @extend .abs-header-icons-label;
        }

        .counter.qty {
            @extend .abs-number-badge;

            &.empty {
                display: none;
            }

            .loader {
                > img {
                    @include lib-css(max-width, $minicart-qty__height);
                }
            }
        }

        .counter-label {
            @extend .abs-visually-hidden;
        }

        .counter-number {
            font-size: 11px;
            color: $theme-color-dark-gray5;
        }
    }

    .header-action {
        // .header-action is inside .header-middle-bar because we don't want this in the off-canvas menu
        @include lib-link-all(
            $_link-color: $middle-bar__action__color
        );
        @include lib-css(border, $middle-bar__action__border);
        position: relative;
        z-index: 105;
        display: flex;
        align-items: center;
        height: 45px;
        padding-right: 0;
        border-radius: 4px;
        border-width: 1px 1px 0 1px;

        &:hover {
            text-decoration: none;
        }

        &:not(.showlogin) {
            padding-left: 32px;
        }

        &::after {
            @extend .abs-visually-hidden;
        }
    }
}

@include min-screen($screen__m) {
    .header-middle-bar {
        &__search {
            flex: 1;
            margin-left: 20px;
            padding: 0;
            max-width: 610px;
        }

        &__inner {
            padding: 25px 10px;
        }
    }
}

@include max-screen($screen__l) {
    .header-middle-bar {
        .header-action {
            &:not(.showlogin) {
                padding-left: 15px;
            }
        }
    }
}

@include max-screen($screen__m) {
    .header-middle-bar {
        position: relative;

        &__inner {
            padding: 10px;
        }

        &__logo {
            position: absolute;
            top: 21px;
            left: 50%;
            transform: translateX(-50%);

            img {
                max-width: 130px;
            }
        }

        &__nav-toggle,
        &__header-icons {
            flex: none;
        }

        &__header-icons {
            justify-content: flex-end;
            z-index: 1;
        }

        .login-wrapper,
        .wishlist-wrapper {
            display:none;
        }
    }
}
