@mixin lib-offcanvas-menu(
    $submenu: 'ul',
    $trigger: '&.parent > a',
    $trigger-active: '&[aria-expanded=true] > a'
) {
    &,
    ul {
        @include lib-list-reset-styles();
    }

    li {
        margin: 0;

        #{$trigger} {
            @include lib-icon-font(
                $_icon-font-content: $icon-down,
                $_icon-font-position: after,
                $_icon-font-display: flex
            );
            justify-content: space-between;
        }

        #{$trigger-active} {
            @include lib-icon-font-symbol(
                $_icon-font-content: $icon-up,
                $_icon-font-position: after
            );
        }
    }

    a {
        @include lib-link-all(
            $_link-color: $theme-color-dark-gray5
        );
        display: block;
        padding: 5px 15px;
        font-size: 14px;
    }

    #{$submenu} {
        margin-left: 10px;
    }
}

@include max-screen($screen__m) {
    .nav-sections {
        .footer-menu {
            @include lib-offcanvas-menu();
        }

        .header-middle-bar__user {
            @include lib-offcanvas-menu(
                $trigger: '.header-action[aria-haspopup=true]',
                $trigger-active: '.header-action[aria-expanded=true]'
            );
        }

        .navigation-menu__list {
            @include lib-offcanvas-menu(
                $submenu: 'ul, .navigation-menu__top-link.all'
            );
        }
    }

    .customer-menu[aria-hidden=true] {
        display: none;
    }
}
