.sidebar {
    .amshopby-slider-wrapper {
        position: relative;
        display: flex;
        flex-direction: column;
        margin-bottom: 10px;

        > .items {
            order: 2;
        }

        .amshopby-fromto-wrap {
            margin-bottom: 5px;
            order: 1;
        }

        .am-slider {
            height: 2px;
        }

        .range {
            display: flex;
            justify-content: space-between;
        }

        .input-text {
            max-width: 80px;
            height: 50px;
            width: auto;
            text-align: center;
            padding: 15px;
            border: none;
            border-radius: 50px;
            box-sizing: border-box;
        }

        .ui-slider-horizontal {
            background-color: #cdcdcd;
        }

        .am-filter-go,
        .amshopby-currency {
            display: none;
        }
    }

    .amshopby-slider-container {
        margin: 10px 15px 15px 0;


        .ui-slider-range {
            height: 2px;
            background-color: $theme-color-blue1;
        }

        .am-slider {
            .ui-slider-handle {
                top: -14px;
                display: inline-block;
                margin: 0;
                height: 8px;
                width: 8px;
                border-radius: 6px;
                background-color: $theme-color-blue1;

                &:hover,
                &:active {
                    background-color: darken($theme-color-blue1, 15%);
                }

                &::before {
                    display: none;
                }
            }
        }
    }
}
