.category-grid-widget-container {
    padding: 100px 0;

    .title {
        @include lib-heading(h2);
        margin-bottom: 30px;
    }
}

.category-grid-widget-wrapper {
    display: flex;
    flex-wrap: wrap;
}

.category-grid-widget {
    width: 50%;
    display: flex;
    flex-direction: column;
    padding-right: 10px;
    padding-left: 10px;
    box-sizing: border-box;

    &__details {
        display: flex;
        justify-content: center;
        grid-gap: 10px;

        .films__series {
            background: #CCAACB;
        }

        .muziek {
            background: #F7E9C2;
        }

        .games {
            background: #FE9689;
        }

        .boeken {
            background: #FEC6A1;
        }
    }

    &__block {
        display: flex;
        justify-content: center;
        width: 100%;
        padding: 30px 0;
        border-radius: 25px;
        background: orange;
    }

    &__title {
        @include lib-heading(h3);
        margin-top: 20px;
        font-size: 18px;
    }

    &__count {
        position: absolute;
        top: 50%;
        right: 40px;
        transform: translateY(-50%);
        display: flex;
        justify-content: center;
        align-items: center;
        height: 60px;
        width: 60px;
        border-radius: 50%;
        background-color: $brand__primary__color;
        font-size: 32px;
        font-weight: $font-weight__semibold;
        font-family: $font-family-name__base_slab;
        color: $color-white;
    }

    &__image {
        box-shadow: 0 3px 10px rgba(0, 0, 0, .2);
    }

    img {
        height: 230px;
        width: 135px;
        object-fit: contain;
    }

    .swiper-slide {
        display: flex;
        justify-content: center;
    }

    .swiper-nav__button {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        opacity: 1;

        &::before {
            font-size: 14px;
            color: $color-white;
        }

        &--prev {
            left: 15px;
        }

        &--next {
            right: 15px;
        }
    }
}

@include min-screen($screen__l) {
    .category-grid-widget {
        width: 25%;
    }
}

@include max-screen($screen__m) {
    .category-grid-widget {
        margin-bottom: 20px;

        &__count {
            right: 10px;
        }
    }
}

@include max-screen($screen__xs) {
    .category-grid-widget {
        width: 100%;
    }
}
