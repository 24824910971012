a.btn,
.btn {
    @include lib-button();
    @include lib-link-as-button();
    @include lib-css(border-radius, $button__border-radius);

    &--primary {
        @include lib-button-primary();
    }

    &--secondary {
        @include lib-button-secondary();
    }

    &--sm {
        @include lib-button-s();
    }

    &--md {
        @include lib-button-m();
    }

    &--lg {
        @include lib-button-l();
    }

    &--full {
        @include lib-button-responsive();
    }

    &--as-link {
        @include lib-button-as-link();
    }

    &--rounded {
        @include lib-css(border-radius, $button__border-radius-rounded);
    }

    &--arrow {
        @include lib-icon-font(
            $_icon-font-content: $icon-next,
            $_icon-font-margin: 0 5px 0 -3px,
            $_icon-font-size: 12px
        );
    }

    &--border {
        @include lib-button-reset();
        padding: $button__padding;
        border: 2px solid $color-white;
        border-radius: $button__border-radius-rounded;
        color: $color-white;
        font-size: $button__font-size;
        font-weight: $font-weight__semibold;

        &:hover,
        &:focus,
        &:active {
            color: $theme-color-dark-gray5;
            background-color: $color-white;
            border: 2px solid $color-white;
        }
    }
}

.actions-toolbar {
    .action {
        &-update {
            @extend .btn--primary;
        }

        &-cancel {
            @extend .btn;
        }
    }
}

@include max-screen($screen__s) {
    .btn {
        &--full-s {
            @include lib-button-responsive();
        }
    }
}
