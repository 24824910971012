.socials {
    display: flex;

    p {
        display: flex;
    }

    a {
        @include lib-icon-font(
            $_icon-font-content: false,
            $_icon-font-size: 22px,
            $_icon-font-color: $color-white,
            $_icon-font-display: flex,
            $_icon-font-text-hide: true
        );

        &:not(:last-child) {
            margin-right: 15px;
        }

        &:hover {
            opacity: .7;
        }
    }
}
