.navigation-menu {
    display: flex;

    &__list,
    &__sub-menu-row,
    &__sub-menu-list {
        @include lib-list-reset-styles();
    }

    &__sub-menu-link {
        @include lib-link-all(
            $_link-color: $theme-color-dark-gray5
        );
        margin-bottom: 10px;
    }

    .view-all {
        @include lib-icon-font(
            $_icon-font-content: $icon-chevron-up,
            $_icon-font-position: after,
            $_icon-font-size: 11px,
            $_icon-font-color: $theme-color-dark-gray5,
            $_icon-font-color-hover: $theme-color-dark-gray5,
            $_icon-font-color-active: $theme-color-dark-gray5,
        );
        display: flex;
        align-items: center;
        font-weight: $font-weight__regular;

        &::after {
            position: relative;
            top: -3px;
            transform: rotate(90deg);
        }
    }

    .left {
        flex-grow: 1.3;
        overflow: visible;
    }

    .right {
        max-width: 230px;
        flex-grow: 0.5;
        padding: 0 6px 0 230px;
        justify-content: flex-end;
    }
}

@include min-screen($screen__m) {
    .navigation-menu-container {
        @include lib-css(background-color, $header__navigation__background-color);
    }

    .navigation-menu {
        &__sub-menu-top-link {
            @include lib-link-all(
                $_link-color: $theme-color-dark-gray5
            );
            margin-bottom: 10px;
            font-weight: $font-weight__medium;

            &:not(.all) {
                display: block;
            }
        }

        &-container {
            position: relative;
        }

        &__list {
            display: flex;
            justify-content: space-between;
            overflow-x: auto;
            white-space: nowrap;
            flex: 1;

            > div {
                flex: 1;
            }
        }

        &__item {
            margin: 0;

            &.parent {
                .navigation-menu__top-link {
                    @include lib-icon-font(
                        $_icon-font-content: $icon-chevron-down,
                        $_icon-font-position: after,
                        $_icon-font-margin: 0 0 0 2px,
                        $_icon-font-color: $theme-color-dark-gray5,
                        $_icon-font-size: 10px
                    );
                }
            }

            &:not(:last-of-type) {
                margin-right: 30px;
            }
        }

        .last-two {
            width: 130px;
        }

        &__top-link {
            @include lib-link-all(
                $_link-color: $header__navigation__top-link-color
            );
            display: inline-block;
            padding: 15px 0;
            font-weight: $font-weight__light;

        }

        &__sub-menu-container {
            position: absolute;
            z-index: $z-index__megamenu;
            top: 100%;
            left: 0;
            right: 0;
            width: 100%;
            padding: 30px 0 10px;
            background-color: $color-white;
            border-top: 1px solid $border-color__base;
            border-bottom: 1px solid $border-color__base;
            box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1)
        }

        &__sub-menu-row {
            @include make-row();
        }

        &__sub-menu-column {
            @include make-col-ready();
            @include make-col(6);
            margin-bottom: 20px;
        }

        &__sub-menu-list {
            margin-top: 5px;
        }

        &__top-linkall {
            display: none;
        }

        &__sub-menu-item {
            display: block;
            line-height: 1.2;
            margin: 0;

            &:not(:last-child) {
                margin-bottom: 10px;
            }
        }
    }

}

@include min-screen($screen__l) {
    .navigation-menu {
        &__sub-menu-column {
            @include make-col(6);
            margin-bottom: 0;
            padding-bottom: 20px;

            &::after {
                content: '';
                position: absolute;
                top: 0;
                right: 25px;
                bottom: 0;
                width: 1px;
                background-color: $border-color__base;
            }

            &:nth-child(4n)::after {
                display: none;
            }
        }
    }
}

@include max-screen(1135px) {
    .ui-icon-carat-1-e {
        display: none;
    }

    .navigation-menu {
        padding: 0 15px 0 15px;

        .left {
            flex-grow: 2.5;
            padding-right: 70px;
        }

        .right {
            flex-grow: 0.8;
            padding: 0;
        }
    }
}

@include max-screen($screen__m) {
    .nav-sections .nav-sections-items {
        .navigation-menu {
            flex-direction: column;

            &__item a {
                @include lib-link-all(
                    $_link-color: $theme-color-dark-gray5
                );
            }

            &__list {
                flex-direction: column;

                a {
                    padding: 5px 0;
                    font-size: 14px;
                }
            }

            &__top-link {
                margin: 0;
                padding: 0;
                font-size: 15px;
                font-weight: $font-weight__semibold;

                &:hover {
                    text-decoration: none !important;
                }

            }

            &__sub-menu-list {
                margin-left: 10px;
            }

            &__sub-menu-link {
                margin: 0;
            }

            &__sub-menu-row {
                margin-left: 0;
            }
        }

        .showcart {
            padding-left: 11px;
            position: relative;

            &::before {
                content: '';
            }

            .counter {
                padding-left: 4px;
                position: absolute;
                right: 5px;
                top: 0;
            }
        }
    }
}

//
//  Debugging
//  _____________________________________________
//
//.navigation-menu__item:first-child > div {
//    display: block !important;
//}
