.header-notice-bar {
    padding: 8px 0;
    background-color: $theme-color-blue1;
    color: $color-white;

    .container {
        display: flex;
        justify-content: space-between;
    }

    .usps-container-desktop {
        display: flex;
        align-items: center;
        margin: 0;
        padding: 0;

        li {
            @include lib-icon-font(
                $_icon-font-content: $icon-success,
                $_icon-font-size: 16px,
                $_icon-font-margin: 0 4px 0 0,
                $_icon-font-color: $color-white
            );
            display: flex;
            align-items: center;
            margin: 0;
            font-size: 14px;

            &:not(:last-child) {
                margin-right: 10px;
            }

            p {
                margin-bottom: 0;
                font-size: 14px;
            }
        }
    }

    .swiper-container {
        flex: 1;
        margin: 0;
        padding: 0;
    }

    .swiper-slide {
        @include lib-icon-font(
            $_icon-font-content: $icon-success,
            $_icon-font-size: 16px,
            $_icon-font-margin: 0 4px 0 0,
            $_icon-font-color: $color-white
        );
        display: flex;
        align-items: center;
        font-size: 14px;
        opacity: 0;

        p {
            margin-bottom: 0;
            font-size: 14px;
        }
    }

    .swiper-slide-active {
        opacity: 1;
    }
}

.header-top-bar {
    @include lib-css(display, $top-bar__display);
    padding: 5px 0;
    border-bottom: 1px solid $border-color__base;
    font-size: 14px;
}

.header-top-bar-inner {
    display: flex;
    justify-content: space-between;
}

.header-top-bar-usps-container {
    @extend .abs-usps-horizontal;
}

@include max-screen($screen__l) {
    .header-phonenumber {
        display: none;
    }
}

@include max-screen($screen__m) {
    .header-top-bar {
        display: none;
    }

    .header-notice-bar {
        .usps-container-desktop {
            display: none;
        }
    }
}

@include max-screen($screen__s) {
    .header-top-bar {
        display: none;
    }
}

@include min-screen($screen__m) {
    .header-notice-bar {
        .usps-container {
            display: none;
        }
    }
}
