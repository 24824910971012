//
//  Product Lists
//  _____________________________________________

.block-related-container,
.block-upsell-container {
    margin: 0;
    padding: 100px 0;
}

.products {
    margin-top: $indent__l;
    margin-bottom: $indent__l;

    &.swiper-container {
        padding: 20px 0;
    }

    &.products-related,
    &.products-upsell,
    &.products-crosssell {
        .product-items {
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            width: 100%;

            &.swiper-wrapper {
                flex-wrap: nowrap;
            }
        }
    }

    &.grid {
        .product {
            &-item {
                &:hover {
                    box-shadow: 0 3px 10px rgba(0, 0, 0, .2);
                    border-radius: 6px;
                }

                &-photo {
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: center;
                    margin-bottom: 15px;
                    border-radius: 6px;
                }
            }
        }
    }
}

.product {
    &-items {
        @extend .abs-reset-list;
    }

    &-item {
        @extend .abs-add-box-sizing;
        vertical-align: top;
        list-style: none;

        .products-grid & {
            display: inline-block;
        }

        .products-grid &-name {
            @include text-truncate($font-size__m);
            font-family: $font-family__second;
            font-weight: 600;

            > a {
                font-weight: inherit;
                color: $theme-color-dark-gray5;
            }
        }

        .product-label {
            max-width: 60px;
        }

        &-image-wrapper,
        &-image-photo {
            margin: 0 auto;
            padding: 0;
        }

        &-name {
            @extend .abs-product-link;
            display: block;
            word-wrap: break-word;
            hyphens: auto;
            margin-bottom: 5px;
        }

        &-info {
            max-width: 100%;
            padding: 15px;
            border-radius: 6px;
        }

        &-actions {
            display: none;

            .actions-secondary {
                & > .action {
                    @extend .abs-actions-addto;
                    &::before {
                        margin: 0;
                    }

                    span {
                        @extend .abs-visually-hidden;
                    }
                }
            }
        }

        .product-reviews-summary {
            .rating-summary {
                margin: 0 4px 0 0;
            }

            .reviews-actions {
                font-size: $font-size__s;
                margin-top: 5px;
                text-transform: lowercase;
            }
        }

        .price-final_price.price-box {
            display: flex;
            align-items: flex-end;
            flex-wrap: wrap;

            .old-price {
                order: 2;

                .price {
                    font-size: 16px;
                    color: $theme-color-dark-gray1;
                }
            }

            .price {
                white-space: nowrap;
                font-size: $font-size__l;
                color: $theme-color-red;
            }

            .price-label {
                display: none; // Hide the price label
            }
        }

        .special-price,
        .minimal-price {
            margin: 0;

            .price-wrapper {
                display: inline-block;
            }

            .price-including-tax + .price-excluding-tax {
                display: block;
            }
        }

        .special-price {
            display: block;
        }

        .minimal-price {
            .price-container {
                display: block;
            }
        }

        .minimal-price-link {
            margin-top: 5px;
        }

        .minimal-price-link,
        .price-excluding-tax,
        .price-including-tax {
            display: block;
            white-space: nowrap;
        }

        .price-from,
        .price-to {
            margin: 0;
        }

        .tocompare {
            @include lib-icon-font-symbol($icon-compare-full);
        }

        .tocart {
            @extend .btn--rounded;
            @include lib-icon-font(
                    $icon-expand,
                $_icon-font-size: 12px,
                $_icon-font-text-hide: true
            );
            @include lib-icon-font(
                    $icon-cart,
                $_icon-font-size: 17px,
                $_icon-font-position: after
            );
            white-space: nowrap;
        }
    }
}

.price-container {
    .price-including-tax + .price-excluding-tax,
    .weee {
        margin-top: $indent__xs;
    }

    .weee {
        &::before {
            content: '(' attr(data-label) ': ';
        }

        &::after {
            content: ')';
        }

        + .price-excluding-tax {
            &::before {
                content: attr(data-label) ': ';
            }
        }
    }
}

.products-list {
    .photo-wrapper {
        position: relative;
        margin-right: 20px;
    }

    .product {
        &-item {
            width: 100%;

            &.item {
                padding-left: 0;
            }

            &-name {
                margin-top: 0;
            }

            &-info {
                display: flex;
                padding: 20px 0;
                border-bottom: 1px solid $border-color__base;
            }
        }

        .price-box {
            margin-bottom: 5px;
        }
    }

    .product-image-wrapper {
        @extend .abs-reset-image-wrapper;
    }
}

//
//  Mobile
//  _____________________________________________

@include max-screen($screen__m) {
    .products-grid {
        .product-item {
            width: 50%;
        }

        .products.product-items {
            .swatch-attribute {
                display: none;
            }
        }
    }
}

@include max-screen($screen__s) {
    .products-list .product {
        &-item {
            table-layout: fixed;

            &-photo {
                padding: 0 $indent__s $indent__s 0;
                width: 30%;
            }
        }
    }

    .product-reviews-summary,
    .product-item-details [class^=swatch] {
        display: none;
    }
}

//
//  Desktop
//  _____________________________________________

@include min-screen($screen__m) {
    .product {
        &-item {
            .products-list &-name {
                font-size: 17px;
            }

            .product-overview &-name {
                @include text-truncate(17px);
            }

            &-actions {
                display: flex;
                flex-wrap: wrap;
                align-items: center;

                .actions-primary + .actions-secondary {
                    margin: 10px 0;
                    padding-left: 10px;
                    white-space: nowrap;

                    & > * {
                        white-space: normal;
                    }

                    & > .action:not(:last-child) {
                        margin-right: 10px;
                    }
                }
            }
        }
    }
}
