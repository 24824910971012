//
//  Buttons variables
//  _____________________________________________

//
//  Buttons
//  ---------------------------------------------

//  Font style
$button__font-family                          : $font-family__base !default;
$button__font-size                            : 16px !default;
$button__font-weight                          : $font-weight__semibold !default;
$button__line-height                          : $font-size__base + 2 !default;
$button__margin                               : 0 !default;
$button__padding                              : 18px 50px !default; // is set up to false when buttons don't depend on side paddings or have fixed width
$button__width                                : false !default; // is set up to false when buttons depend on side paddings or to fixed value when needed

//  Display settings
$button__display                              : inline-block !default;
$button__cursor                               : pointer !default;
$button__border-radius                        : 50px !default;
$button__border-radius-rounded                : 50px !default;

$button__disabled__opacity                    : 0.5 !default;

//  Default                                   = secondary button
$button__color                                : $theme-color-dark-gray3 !default;
$button__background                           : $color-white !default;
$button__border                               : 2px solid $color-white !default;
$button__border__bottom__width                : false !default;
$button__border__bottom__color                : false !default;
$button__gradient-color-start                 : false !default;
$button__gradient-color-end                   : false !default;

$button__hover__color                         : darken($button__color, 5%) !default;
$button__hover__background                    : false !default;
$button__hover__border                        : 2px solid $color-white !default;
$button__hover__border__bottom__width         : $button__border__bottom__width !default;
$button__hover__border__bottom__color         : $button__border__bottom__color !default;
$button__hover__gradient-color-start          : false !default;
$button__hover__gradient-color-end            : false !default;

$button__active__color                        : darken($button__color, 5%) !default;
$button__active__background                   : $button__hover__background !default;
$button__active__border                       : $button__border !default;
$button__active__border__bottom__width        : $button__border__bottom__width !default;
$button__active__border__bottom__color        : $button__border__bottom__color !default;
$button__active__gradient-color-start         : false !default;
$button__active__gradient-color-end           : false !default;

//  Primary button
$button-primary__line-height                  : false !default;
$button-primary__width                        : false !default;
$button-primary__margin                       : false !default;
$button-primary__padding                      : $button__padding !default;
$button-primary__gradient                     : false !default;
$button-primary__gradient-direction           : false !default;

$button-primary__background                   : $brand__primary__color !default;
$button-primary__border                       : 2px solid $button-primary__background !default;
$button-primary__border__bottom__width        : false !default;
$button-primary__border__bottom__color        : false !default;
$button-primary__color                        : $color-white !default;
$button-primary__gradient-color-start         : false !default;
$button-primary__gradient-color-end           : false !default;

$button-primary__hover__background            : darken($brand__primary__color, 5%) !default;
$button-primary__hover__border                : 2px solid $button-primary__hover__background !default;
$button-primary__hover__border__bottom__width : $button-primary__border__bottom__width !default;
$button-primary__hover__border__bottom__color : $button-primary__border__bottom__color !default;
$button-primary__hover__color                 : $button-primary__color !default;
$button-primary__hover__gradient-color-start  : false !default;
$button-primary__hover__gradient-color-end    : false !default;

$button-primary__active__background           : $button-primary__hover__background !default;
$button-primary__active__border               : 2px solid $button-primary__active__background !default;
$button-primary__active__border__bottom__width : $button-primary__border__bottom__width !default;
$button-primary__active__border__bottom__color : $button-primary__border__bottom__color !default;
$button-primary__active__color                : $button-primary__color !default;
$button-primary__active__gradient-color-start : false !default;
$button-primary__active__gradient-color-end   : false !default;

//  Secondary button
$button-secondary__line-height                  : false !default;
$button-secondary__width                        : false !default;
$button-secondary__margin                       : false !default;
$button-secondary__padding                      : $button__padding !default;
$button-secondary__gradient                     : false !default;
$button-secondary__gradient-direction           : false !default;

$button-secondary__background                   : $theme-color-yellow2 !default;
$button-secondary__border                       : 2px solid $theme-color-yellow2 !default;
$button-secondary__border__bottom__width        : false !default;
$button-secondary__border__bottom__color        : false !default;
$button-secondary__color                        : $color-white !default;
$button-secondary__gradient-color-start         : false !default;
$button-secondary__gradient-color-end           : false !default;

$button-secondary__hover__background            : darken($button-secondary__background, 5%) !default;
$button-secondary__hover__border                : 2px solid $button-secondary__hover__background !default;
$button-secondary__hover__border__bottom__width : $button-secondary__border__bottom__width !default;
$button-secondary__hover__border__bottom__color : $button-secondary__border__bottom__color !default;
$button-secondary__hover__color                 : $button-secondary__color !default;
$button-secondary__hover__gradient-color-start  : false !default;
$button-secondary__hover__gradient-color-end    : false !default;

$button-secondary__active__background           : $button-secondary__hover__background !default;
$button-secondary__active__border               : 1px solid $button-secondary__active__background !default;
$button-secondary__active__border__bottom__width : $button-secondary__border__bottom__width !default;
$button-secondary__active__border__bottom__color : $button-secondary__border__bottom__color !default;
$button-secondary__active__color                : $button-secondary__color !default;
$button-secondary__active__gradient-color-start : false !default;
$button-secondary__active__gradient-color-end   : false !default;

//  Gradient button
$button__gradient                             : false !default; // [true|false] - button has a gradient background
$button__gradient-direction                   : false !default; // button gradient direction if button has a gradient background

//  Button with icon
$button-icon__use                             : false !default;
$button-icon__content                         : $icon-settings !default;
$button-icon__font                            : $icon-font !default;
$button-icon__font-size                       : 22px !default;
$button-icon__line-height                     : $button-icon__font-size !default;
$button-icon__color                           : inherit !default;
$button-icon__margin                          : 0 !default;
$button-icon__vertical-align                  : top !default;
$button-icon__position                        : $icon__position !default;
$button-icon__text-hide                       : false !default;

$button-icon__hover__font-color               : inherit !default;
$button-icon__active__font-color              : inherit !default;

//  Large button
$button__font-size__l                         : 16px !default;
$button__line-height__l                       : $font-size__l + 4 !default;
$button__padding__l                           : 10px 22px !default;
$button__padding__l__desktop                  : 13px 27px !default;
$button__border__width__l                     : 0 0 4px 0 !default;
$button__border__bottom__width__l             : false !default;

//  Medium button
$button__font-size__m                         : 15px !default;
$button__line-height__m                       : $button__font-size__m + 4 !default;
$button__padding__m                           : 10px 22px !default;
$button__padding__m__desktop                  : 10px 25px !default;
$button__border__width__m                     : 0 0 4px 0 !default;
$button__border__bottom__width__m             : false !default;

//  Small button
$button__font-size__s                         : 13px !default;
$button__line-height__s                       : $button__font-size__s + 1 !default;
$button__padding__s                           : 8px 20px !default;
$button__border__bottom__width__s             : false !default;
