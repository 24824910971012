.block.newsletter {
    margin-bottom: 0;

    .newsletter-text p {
        color: $color-white;
    }

    .form.subscribe {
        display: flex;
        width: 100%;
    }

    .field.newsletter {
        position: relative;
        flex: 1;
        display: flex;
        margin: 0;

        .control {
            display: flex;
            width: 100%;
        }

        input[type="email"] {
            height: 54px;
            border: none;
            border-radius: 6px;
            padding-left: 65px;
            color: $theme-color-dark-gray5;
        }
    }

    .fieldset {
        margin: 0;
        padding: 0;
        vertical-align: top;
    }

    .title {
        display: none;
    }

    .label {
        position: absolute;
        top: calc(50% + 1px);
        left: 15px;
        transform: translateY(-50%);
        color: $theme-color-dark-gray5;
    }

    .mage-error:not(input) {
        position: absolute;
        top: 100%;
        left: 0;
        padding: 4px 10px;
        border-radius: 6px;
        background-color: #e02b27;
        color: $color-white;
    }

    button.subscribe {
        @include lib-button-reset();
        @include lib-icon-font(
            $_icon-font-content: $icon-chevron-up,
            $_icon-font-size: 14px,
            $_icon-font-color: $theme-color-dark-gray5,
            $_icon-font-color-hover: $theme-color-dark-gray5,
            $_icon-font-color-active: $theme-color-dark-gray5,
        );
        position: absolute;
        top: 50%;
        right: 0;
        transform: translateY(-50%);
        height: 100%;
        padding: 0 20px;
        background-color: $color-white;

        &::before {
            transform: rotate(90deg);
        }
    }
}
