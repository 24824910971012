@include min-screen($screen__m) {
    .wishlist-wrapper {
        .label {
            font-size: 12px;
            color: $theme-color-dark-gray1;
        }

        .header-action {
            display: flex;
            flex-direction: column;
        }

        .header-action {
            @include lib-icon-font(
                $_icon-font-content: $icon-wishlist-empty,
                $_icon-font-display: false,
                $_icon-font-size: 22px,
                $_icon-font-line-height : 25px
            );
        }
    }
}

