$footer__background-color: $brand__primary__color !default;

.page-footer {
    @include lib-css(background-color, $footer__background-color);
}

.page-bottom {
    width: 100%;
    display: block;
}

.footer.content {
    padding-top: 80px;
    padding-bottom: 80px;

    .links {
        > li {
            margin: 0 0 8px;
        }
    }

    .switcher-store {
        margin: 0 0 30px;
    }
}

.footer-menu {
    &,
    &__sub-menu-list {
        @include lib-list-reset-styles();
    }
}

.footer-bottom {
    padding: 20px 0;
    display: flex;
    align-items: center;
}

.footer-bottom-wrapper {
    background-color: $color-white;
}

.footer-columns {
    margin-bottom: 40px;
}

.footer-copyright {
    font-size: 13px;
    color: $theme-text-color;
    flex: auto;
}

.footer-payment-providers {
    flex: auto;

    p {
        float: right;
    }
}

.after-footer-columns {
    .block.newsletter {
        margin-bottom: 20px;

        h4 {
            margin-bottom: 13px;
        }
    }

    p,
    .small {
        margin-bottom: 20px;
        font-size: 16px;
    }

    h4, span {
        color: $color-white;
    }
}

.top-footer {
    background-color: $brand__primary__color;
    color: $color-white;
    border-bottom: 1px solid rgba(255, 255, 255, .25);

    a {
        @include lib-link-all(
            $_link-color: $color-white
        );
    }

    .container {
        padding-top: 80px;
        padding-bottom: 80px;
    }

    &__left {
        margin: 5px 15px 5px 0;
        font-size: 22px;
    }

    &__right {
        ul {
            @include lib-list-reset-styles();
            display: flex;
        }

        li {
            @include lib-icon-font(
                $_icon-font-content: false,
                $_icon-font-margin: 0 10px 0 0,
                $_icon-font-size: 22px
            );
            margin: 0;
            font-weight: $font-weight__medium;

            &:nth-child(1) {
                @include lib-icon-font-symbol(
                    $_icon-font-content: $icon-delivery
                );

                &::before {
                    font-size: 20px;
                }
            }

            &:nth-child(2) {
                @include lib-icon-font-symbol(
                    $_icon-font-content: $icon-undo
                );
            }

            &:nth-child(3) {
                @include lib-icon-font-symbol(
                    $_icon-font-content: $icon-moon
                );

                &::before {
                    font-size: 19px;
                }
            }

            &:not(:first-child) {
                margin-left: 25px;
            }
        }
    }
}

.footer-columns {
    .newsletter,
    .footer-menu {
        &__top-link,
        h4 {
            margin-bottom: 5px;
            font-family: $font-family-name__base_slab;
            font-size: 19px;
            font-weight: $font-weight__semibold;
            @include lib-link-all(
                $_link-color: $color-white
            );
            display: inline-block;
        }

        .current-all {
            display: none;
        }
    }
}

@include min-screen($screen__m) {
    .footer-columns {
        @include make-row();
        margin-bottom: 60px;

        .footer-menu {
            display: flex;

            &__item {
                flex: 1;
            }

            &__sub-menu-link {
                @include lib-link-all(
                    $_link-color: $theme-font-color
                );
            }

            &__sub-menu-topall {
                display: none;
            }

            &__sub-menu-item {
                margin-bottom: 8px;
                line-height: 1.2;
            }

            &__sub-menu-column:first-child { // Hide top link all
                display: none;
            }
        }

        .after-footer-columns,
        .footer-menu-container {
            @include make-col-ready();
        }

        .after-footer-columns {
            @include make-col(6);
        }

        .footer-menu-container {
            @include make-col(18);
        }
    }

    .footer {
        &.content {
            .links {
                display: inline-block;
                margin-bottom: 20px;
                padding: 0 50px 0 0;
                vertical-align: top;
            }

            .switcher.store {
                display: inline-block;
                padding-right: 50px;
                vertical-align: top;
            }

            .footer-menu {
                &__top-link-all {
                    display: none;
                }
            }
        }

        .copyright {
            @extend .abs-add-clearfix-desktop;
        }
    }

    .top-footer {
        &__inner {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            margin-bottom: 0;

            h1,
            h3 {
                color: $theme-font-color;
            }
        }
    }
}

//
//  Mobile
//  _____________________________________________

@include max-screen($screen__m) {
    .footer.content {
        .footer-menu {
            margin-right: -$layout-indent__width;
            margin-left: -$layout-indent__width;
            border-top: 1px solid $border-color__base;

            &__item {
                margin-bottom: 0;
                border-bottom: 1px solid $border-color__base;

                &.parent > a {
                    @include lib-icon-font(
                        $_icon-font-content: $icon-down,
                        $_icon-font-position: after,
                        $_icon-font-display: flex
                    );
                }

                &[aria-expanded=true] > a {
                    @include lib-icon-font-symbol(
                        $_icon-font-content: $icon-up,
                        $_icon-font-position: after
                    );
                }
            }

            &__top-link {
                justify-content: space-between;
                position: relative;
                margin: 0;
                padding: 15px $layout-indent__width;
            }

            li {
                margin-bottom: 0;
            }

            &__sub-menu-list {
                display: none;
                padding: 0 $layout-indent__width 15px;

                li:not(:last-child) {
                    margin-bottom: 10px;
                }

                a {
                    @include lib-link-all(
                        $_link-color: $color-white
                    );
                }
            }
        }
    }

    .footer-columns {
        .block.newsletter {
            margin-top: 20px;
        }

        .social-media {
            margin-top: 20px;
        }
    }

    .top-footer {
        display: none;
    }

    .footer-bottom {
        flex-direction: column;
    }

    .copyright {
        margin-bottom: 15px;
    }
}
