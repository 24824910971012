.home-product-sales {
    background: $theme-color-light-gray1;
}

.home-product-selected {
    padding: 100px 0 0 0;

    .block {
        margin: 0;

        &.block-new-products {
            .product.item {
                max-width: 20%;
            }
        }
    }
}

.home-product-sales {
    padding: 100px 0 100px 0;

    .block {
        &.block-new-products {
            .product.item {
                max-width: 20%;
            }
        }
    }

    .home-product-widget-button {
        text-align: center;
    }
}

.home-top-genre,
.home-product-selected,
.home-product-sales {
    margin-bottom: 0;

    .grid-row {
        margin: 0 auto;
    }

    .product-image-container {
        width: 100% !important;
    }

    .block-new-products {
        .block-title {
            margin-bottom: 25px;

            > strong {
                font-size: 28px;
            }
        }

        .block-content {
            min-height: 343px;
        }

        .sales-big {
            text-align: left;
        }

        .big-sale {
            display: block;
        }

        .grid-col-gap {
            grid-gap: 20px;

            .grid-col-7-custom {
                width: calc(100% / 3 - 20px);

                .product-item-info {
                    padding: 0;
                }
            }
        }
    }

    .home-product-widget-action-button {
        width: 100%;
        display: flex;
        justify-content: center;
    }
}

.home-top-genre,
.content-block,
.home-product-sales {
    > div {
        @extend .container;
    }

    > .category-grid-widget-wrapper {
        padding-top: 100px;
    }
}

@include max-screen($screen__l) {
    .block-new-products {
        > .grid-row {
            flex-direction: column;

            > .grid-col-md {
                flex: 1;

                &:first-child {
                    margin-bottom: 50px;
                }
            }
        }
    }
}

@include max-screen($screen__m) {
    .home-product-sales,
    .home-product-selected {
        .block {
            &.block-new-products {
                .product.item {
                    max-width: 50%;
                }
            }
        }
    }

    .block-new-products {
        > .grid-row {
            > .grid-col-md {
                .grid-col-gap {
                    grid-gap: 0;
                    justify-content: space-between;
                }

                .grid-col-7-custom {
                    width: calc(50% - 10px);
                    margin-bottom: 20px;
                }
            }
        }
    }
}
