$theme-color-blue1       : #60A0B1 !default;
$theme-color-dark-blue1  : #095a9a !default;
$theme-color-dark-blue2  : #0c5460 !default;
$theme-color-dark-blue3  : #084853 !default;
$theme-color-light-blue1 : #eaf3fb !default;
$theme-color-light-blue2 : #ddeaf5 !default;
$theme-color-yellow1     : #ec8918 !default;
$theme-color-yellow2     : #febb47 !default;
$theme-color-red         : #f18972 !default;
$theme-color-light-gray1 : #f6f6f6 !default;
$theme-color-light-gray2 : #d8dce6 !default;
$theme-color-light-gray3 : #bdc5cc !default;
$theme-color-light-gray4 : #e2e5ed !default;
$theme-color-light-gray5 : #f5f5f5 !default;
$theme-color-light-gray6 : #d3d5dc !default;
$theme-color-dark-gray1  : #999999 !default;
$theme-color-dark-gray2  : #444 !default;
$theme-color-dark-gray3  : #666666 !default;
$theme-color-dark-gray4  : #3e3f42 !default;
$theme-color-dark-gray5  : #333333 !default;
$theme-color-green1      : #3bad4b !default;
$theme-font-color        : #ffffff !default;

$theme-paragraph-color   : $theme-color-dark-gray1 !default;
$theme-heading-color     : $theme-color-dark-gray4 !default;

$theme-text-color        : $theme-color-dark-gray1 !default;
$theme-text-color-lighter : $theme-color-dark-gray3 !default;

$theme-icon-color-regular : $theme-color-dark-gray2 !default;
$theme-icon-color-light  : $theme-color-light-gray3 !default;

$theme-border-color      : $theme-color-light-gray4 !default;
$theme-border-color-hover : $theme-color-light-gray6 !default;
