.home-banner-wrapper {
    position: relative;
    background: $theme-color-light-gray1;
}

.banner-image {
    @extend .home-banner-wrapper;
    box-sizing: border-box;
    min-height: 580px;
    display: flex;
    align-items: center;
    background-size: cover;
    background-position: center center;
    background-image: url('../images/homepage/fp-slider.jpg');

    h1 {
        margin-bottom: 30px;
        font-size: 54px;
        color: $color-white;
    }

    h3 {
        margin-bottom: 30px;
    }

    a {
        @extend .btn;
        @extend .btn--primary;
    }

    &__inner {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;
    }
}

.banner-categories {
    @extend .home-banner-wrapper;
    height: 350px;
    box-sizing: border-box;

    &__inner {
        position: relative;
        display: flex;
        justify-content: space-between;
        width: 100%;
        padding: 15px 0;

        .btn {
            width: 100%;
            padding: 18px 0;
            font-family: $font-family__second;
            font-size: 22px;
            text-align: center;
        }
    }

    &__image {
        width: 100%;
        height: 295px;
        margin-bottom: 20px;
        border-radius: 25px;
        background-size: cover;
    }

    &__item {
        width: calc(25% - 10px);

        &--order-1 {
            order: 1;
        }

        &--order-2 {
            order: 2;
        }

        &--order-3 {
            order: 3;
        }

        &--order-4 {
            order: 4;
        }
    }
}

@include min-screen($screen__m) {
    .banner-image {
        .container {
            position: relative;
            bottom: 50px;
            padding-right: 300px;
        }
    }

    .banner-categories {
        &__inner {
            top: -110px;
        }
    }
}

@include max-screen($screen__l) {
    .banner-image {
        background-position: -1000px 0;

        h1 {
            font-size: 45px;
        }

        &__inner {
            padding-left: 15px;
        }
    }

    .banner-categories {
        height: auto;

        .btn {
            font-size: 17px;
        }
    }
}

@include max-screen($screen__m) {
    .banner-categories {
        &__inner {
            flex-wrap: wrap;
        }

        &__item {
            width: calc(50% - 10px);
            margin-bottom: 20px;
        }
    }
}

@include max-screen($screen__xxs) {
    .banner-categories {
        &__item {
            width: 100%;
        }
    }
}
