.product-overview.grid {
    .product-items {
        @include make-row();
    }

    .product-item {
        @include make-col-ready();
        @include make-col(12);
    }
}

//
//  Desktop
//  _____________________________________________

@include min-screen($screen__m) {
    .product-overview.grid {
        .product-item {
            @include make-col(6);
        }
    }
}
