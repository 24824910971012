//
//  Components -> Modals
//  _____________________________________________

//
//  Variables
//  ---------------------------------------------

$modal-title__color                   : $text__color !default;
$modal-title__border                  : 1px solid $border-color__base !default;

$modal-popup-title__font-size         : 20px !default;
$modal-popup-title-mobile__font-size  : 16px !default;

$modal-slide__first__indent-left      : 44px !default;
$modal-slide-mobile__background-color : $color-white !default;
$modal-overlay__background-color      : $default__overlay__color !default;

$modal-action-close__color            : $primary__color !default;
$modal-action-close__font-size        : 14px !default;
$modal-action-close__hover__color     : darken($primary__color, 10%) !default;

$modal-slide-action-close__padding    : $modal-slide-header__padding-vertical - 1rem $modal-popup__padding - 1rem !default;

//
//  Amasty
//  _____________________________________________

.amgdprcookie-modal-container.modal-popup.modal-slide {
    max-width: 500px;

    .amgdprcookie-modal-template {
        background-color: #FFFFFF;
        margin-left: 0;

        .amgdprcookie-groups-container {
            height: auto !important;
        }

        .amgdprcookie-policy-container {
            padding-bottom: 200px;
            position: relative;

            .amgdprcookie-buttons-block {
                position: absolute;
                width: 100%;
                padding: 20px;
                box-sizing: border-box;
                left: 0;
            }
        }
    }
}

.amgdprcookie-policy-container {
    padding: 0 24px;
}

.amgdprcookie-modal-container {

    .modal-popup {
        .modal-slide {
            background-color: #FFFFFF;
        }
    }

    .modal-header {
        display: none;
    }

    .-modal {
        margin-right: 10px;
    }

    .amgdprcookie-buttons-block {
        flex-direction: column;

        .amgdprcookie-button {
            width: 100%;
            flex-basis: 100%;
            background-color: $theme-color-blue1;
            font-size: 13px;
            font-weight: $font-weight__semibold;
            text-transform: initial;
        }

        .amgdprcookie-button.-save {
            margin-bottom: 15px;
            margin-left: 0;
        }
    }

    .amgdprcookie-text,
    .amgdprcookie-text-container,
    .amgdprcookie-link {
        font-size: 14px;
    }

    .amgdprcookie-link {
        display: block;
        margin-top: 10px;
        margin-left: 15px;
        text-align: left;
    }
}

//
//  Common
//  _____________________________________________

.modal-custom,
.modal-popup,
.modal-slide {
    .action-close {
        @include lib-button-reset();
        @include lib-button-icon(
            $icon-remove,
            $_icon-font-color       : $theme-icon-color-regular,
            $_icon-font-size        : $modal-action-close__font-size,
            $_icon-font-line-height : $modal-action-close__font-size,
            $_icon-font-text-hide   : true
        );
        margin-left: auto;
        line-height: 1;

        &:hover {
            &::before {
                color: $modal-action-close__hover__color;
            }
        }
    }
}

.modal-custom {
    .action-close {
        @include lib-css(margin, $indent__m);
    }
}

.modal-popup {
    .modal-header {
        @include lib-css(border-bottom, $modal-title__border);
    }

    .modal-title {
        @include lib-heading(h3);
        margin-bottom: 0;
        min-height: 1em;
        word-wrap: break-word;
    }
}

.modal-slide {
    .page-main-actions {
        margin-bottom: calc(#{$modal-slide-header__padding-vertical} - #{$indent__l / 2});
        margin-top: $modal-slide-header__padding-vertical;
    }
}

.modals-overlay {
    @include lib-css(background-color, $modal-overlay__background-color);
    bottom: 0;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
}

.modal-inner-wrap {
    z-index: $z-index__modals;
}

body {
    &._has-modal-custom {
        .modal-custom-overlay {
            height: 100vh;
            left: 0;
            position: fixed;
            top: 0;
            width: 100vw;
            z-index: $overlay__z-index;
        }
    }
}

//
//  Mobile
//  _____________________________________________

@include max-screen($screen__m) {

    .amgdprcookie-modal-template {
        width: 100%;
        background-color: #FFFFFF;
    }

    .amgdprcookie-policy-container {
        padding: 0 24px;
    }

    .custom-slide {
        @include lib-modal();
        @include lib-modal-slide();

        &._show {
            -webkit-overflow-scrolling: touch;
            overflow-x: hidden;
            overflow-y: auto;
        }

        .modal-inner-wrap {
            @include lib-css(background-color, $modal-slide-mobile__background-color);
            box-sizing: border-box;
            height: auto;
            min-height: 100%;
        }
    }

    body {
        &._has-modal-custom {
            height: 100vh;
            overflow: hidden;
            width: 100vw;

            .modal-custom-overlay {
                @include lib-css(background-color, $modal-overlay__background-color);
            }
        }
    }
}

@include max-screen($screen__m) {
    .modal-popup {
        &.modal-slide {
            .modal-inner-wrap[class] {
                @include lib-css(background-color, $modal-slide-mobile__background-color);
            }

            &._inner-scroll {
                &._show {
                    -webkit-overflow-scrolling: touch;
                    overflow-y: auto;
                    max-width: 500px;
                }

                .modal-inner-wrap {
                    height: auto;
                    min-height: 100%;
                }
            }
        }

        .modal-title {
            @include lib-css(font-size, $modal-popup-title-mobile__font-size);
        }
    }
}

//
//  Desktop
//  _____________________________________________

@include min-screen($screen__m) {
    .modal-popup {
        &.modal-slide {
            .modal-footer {
                padding-top: 20px;
                @include lib-css(border-top, $modal-title__border);
            }
        }
    }
}
